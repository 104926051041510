.nav-wrap
  position: relative
  display: flex
  flex-flow: column nowrap
  flex-shrink: 0
  width: 280px
  height: 588px
  padding: 3.6rem 4rem 3.7rem 4rem
  background-color: transparent
  color: $white
  overflow: hidden
  @include media("<large")
    padding: 3rem 3.3rem 2.6rem 3.3rem
    height: 488px
    width: 232px
  @include media("<desktop")
    padding: 2.1rem 2.5rem 2.1rem 2.5rem
    height: 378px
    width: 180px
  @include media("<tablet")
    padding: 2.6rem 2.5rem 2.1rem 2rem
    height: 360px
    width: 280px


.gradient-container
  position: absolute
  top: 0
  left: 0
  bottom: 0
  right: 0
  z-index: 1
  background-color: transparent

.nav-bg-gradient
  position: absolute
  top: 0
  left: 0
  height: 300px
  width: 300px
  transform: scale(0.5)
  animation-name: gradient-grow
  animation-duration: 6s
  animation-fill-mode: forwards
  animation-iteration-count: 1

.nav-bg-gradient--test
  height: 300px
  width: 300px
  animation: none
  background-image: radial-gradient(circle at center, $webd 43%, transparent 71%)

@keyframes gradient-grow
  0%
    opacity: 0
    transform: scale(0.5)
  10%
    opacity: 1
  50%
    transform: scale(20)
  100%
    transform: scale(20)

.nav
  z-index: 2

.nav__list
  @include resetlist()
  margin: 0 -2rem
  list-style: none
  @include media("<large")
    margin: 0 -20px
  @include media("<desktop")
    margin: 7px -20px
  @include media("<tablet")
    position: absolute
    opacity: 0
    height: 0
    overflow: hidden
    transition: height $transition

  &--show
    opacity: 1
    height: 400px
    width: 100%


// .nav-el

.nav__link
  position: relative
  display: block
  padding: 1.15rem 0 1.15rem 2rem
  text-decoration: none
  color: $white
  font-size: 1.5rem
  font-family: "PT Mono", serif
  font-weight: 600
  user-select: none
  transition: background-color $transition, font-weight $transition*2
  //&:after
    content: ""
    position: absolute
    right: 16px
    top: 50%
    width: 10px
    height: 10px
    background-color: $white
    clip-path: polygon(0 33%, 67% 33%, 67% 100%, 100% 100%, 100% 0, 0 0)
    opacity: 0
    transform: translate(-30px, -50%) rotate(45deg)
    transition: transform $transition, opacity $transition

  &.active
    background-color: rgba(255, 255, 255, 0.15)
    transition: background-color $transition, text-shadow $transition
    //&:after
      opacity: 1
      transform: translate(0, -50%) rotate(45deg)
    @include media("<desktop")
      background-color: transparent

  &:hover
    background-color: rgba(255, 255, 255, 0.15)
  @include media("<large")
    font-size: 1.3rem
    padding: 0.9rem 0 0.9rem 1.9rem
  @include media("<desktop")
    font-size: 1.1rem
    padding: 0.6rem 0 0.6rem 2rem
  @include media("<tablet")
    padding: 1.1rem 0 1.1rem 2rem
    background-color: transparent
    font-size: 1.8rem

.nav__logo
  margin: 3px 0 16px 0
  z-index: 2
  @include media("<large")
    margin: 0 0 11px 0
    svg
      width: 65px
  @include media("<desktop")
    margin: 0
    height: 25px
    svg
      width: 56px
  @include media("<tablet")
    margin-left: -1rem
    margin-bottom: 20px
    svg
      width: 100px

.nav__mobileBtn
  display: none
  @include media("<tablet")
    position: absolute
    right: 20px
    top: 20px
    display: block
    width: 25px
    height: 22px
    z-index: 3
    appearance: none
    border: none
    background: none
    font-weight: 900
    color: $white
    font-size: 3.0rem
    -webkit-tap-highlight-color: transparent
    &:focus, &:active
      background: none
      outline: none
      border: none
      appearance: none


