html
  font-size: 62.5%
  font-family: "Roboto", sans-serif
  font-weight: 400
  *
    box-sizing: border-box

body
  margin: 0

#root
  height: 100vh
  display: flex
  justify-content: center
  align-items: center
  @include media("<tablet")
    height: 90vh
    height: calc(var(--vh, 1vh) * 97)

.logo
  margin: 15px
