$webd: #1BBD5C
$graphd: #1E9FD7
$print: #1C43D0
$branding: #B41DDA
$ui: #DC186A

$white: #ffffff
$black: #000000
$text: #1A1A1A
$bg: #F5F5F5

$transition: 300ms
