.main-wrap
  position: relative
  display: flex
  width: 1060px
  height: 588px
  font-size: 1.6rem
  @include media("<large")
    width: 880px
    height: 488px
  @include media("<desktop")
    width: 680px
    height: 378px
  @include media("<tablet")
    width: 100%
    height: 100vh
    overflow: hidden
    justify-content: center
    align-items: center
