.about-me

  &__wrap
    margin-top: auto
    z-index: 2
    transition: opacity $transition/2
    pointer-events: all

    &--hidden
      pointer-events: none
      .about-me__face-gif, .about-me__text, .about-me__link
        opacity: 0

  &__text
    margin: 0 -2rem 1rem 0
    font-size: 1.1rem
    line-height: 1.8rem
    padding-right: 23px
    font-family: "Roboto", sans-serif
    font-weight: 400
    color: $white
    @include media("<large")
      padding-right: 10px
      margin-bottom: 1rem
      font-size: 1rem
      line-height: 1.6rem
    @include media("<desktop")
      padding-right: 15px
      font-size: 0.9rem
      line-height: 1.4rem
    @include media("<tablet")
      margin: 0.5rem 0
      font-size: 1.2rem
      line-height: 1.8rem

  &__text-br-md
    @include media("<large")
      display: none

  &__text-br-sm
    display: none
    @include media("<large")
      display: inline
    @include media("<desktop")
      display: none
    @include media("<tablet")
      display: none

  &__text-br-xs
    display: none
    @include media("<large")
      display: none
    @include media("<desktop")
      display: inline
    @include media("<tablet")
      display: none


  &__links
    @include resetlist()
    margin: 0 0 0.2rem -0.4rem
    display: flex
    flex-flow: row wrap
    flex-shrink: 0
    @include media("<large")
      margin-left: -0.6rem
    @include media("<desktop")
      margin-left: -0.1rem
    @include media("<tablet")
      margin: 1.7rem 0 1.7rem -0.2rem
  &__element
    flex-shrink: 0


  &__link
    display: flex
    justify-content: center
    align-items: center
    width: 28px
    height: 28px
    margin-right: 10px
    color: $white
    //noinspection ALL
    @include media("<large")
      width: 26px
      height: 26px
    @include media("<desktop")
      width: 20px
      height: 20px
      margin-right: 14px
    @include media("<tablet")
      width: 26px
      height: 26px

    svg
      width: 18px
      height: 18px
      fill: $white
      will-change: transform
      transition: transform $transition
      @include media("<large")
        width: 16px
        height: 16px
      @include media("<desktop")
        width: 14px
        height: 14px
      @include media("<tablet")
        width: 22px
        height: 22px

    &:hover
      @include media(">tablet")
        svg
          transform: scale(1.1)

    //&--mail
    //  padding-bottom: 1px
    //  svg
    //    width: 34px
    //    height: 34px
    //    @include media("<tablet")
    //      width: 28px
    //      height: 28px


  &__copyright
    display: block
    margin: 1rem -2rem 0 0
    opacity: 0.5
    font-size: 1.1rem
    a
      color: $white
    @include media("<large")
      font-size: 1rem
    @include media("<desktop")
      font-size: 0.9rem
      margin-top: 0.5rem
    @include media("<tablet")
      font-size: 1.1rem
      margin-top: 0.5rem


.about-me__face-gif
  width: 80px
  margin-left: -2rem
  @include media("<large")
    width: 66px
    margin: 0 0 3px -15px
  @include media("<desktop")
    width: 50px
    margin-left: -9px
  @include media("<tablet")
    width: 100px
    margin-left: -21px
