@mixin resetlist
  padding-left: 0
  margin: 0
  list-style: none

@mixin resetmargin
  margin: 0
  padding: 0

@mixin resetinteractive
  text-decoration: none
  background: none
  border: none
