$font_path: 'public/fonts/'

@font-face
  font-family: 'PT Mono'
  src: url("../fonts/ptmonobold.woff2") format("woff2"), url("../fonts/ptmonobold.woff") format("woff")
  font-weight: 600
  font-style: normal

@font-face
  font-family: 'PT Mono'
  src: url("../fonts/ptmono.woff2") format("woff2"), url("../fonts/ptmono.woff") format("woff")
  font-weight: 400
  font-style: normal

@font-face
  font-family: 'Roboto'
  src: url("../fonts/Roboto-Italic.woff2") format("woff2")
  font-weight: 400
  font-style: italic

@font-face
  font-family: 'Roboto'
  src: url("../fonts/Roboto-Regular.woff2") format("woff2")
  font-weight: 400
  font-style: normal
