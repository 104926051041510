.section-title
  font-family: "PT Mono", serif
  font-size: 4.4rem
  line-height: 4.4rem
  color: $black
  @include media("<desktop")
    font-size: 3.4rem
  @include media("<tablet")
    font-size: 3rem
    line-height: 3.2rem

.main__text
  font-size: 1.6rem
  line-height: 2.4rem
  @include media("<desktop")
    font-size: 1.4rem
    line-height: 1.8rem
  @include media("<tablet")


.italics
  font-style: italic

