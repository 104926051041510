@keyframes mainShow
  0%
    transform: translateX(200%)
  50%
    transform: translateX(100%)
  100%
    transform: translateX(0)

@keyframes mainHide
  0%
    transform: translateX(0)
  50%
    transform: translateX(100%)
  100%
    transform: translateX(200%)

.main
  display: flex
  max-height: 588px
  width: 780px
  background-color: $bg
  overflow: hidden
  @include media("<tablet")
    margin-left: 0
    z-index: 5
    position: absolute
    will-change: transform
    transform: translateX(200%)
    width: 280px
    height: 360px


.main--mobShow
  @include media("<tablet")
    animation-name: mainShow
    animation-delay: 300ms
    animation-duration: 300ms
    animation-iteration-count: 1
    animation-timing-function: linear
    animation-fill-mode: forwards

.main--mobHide
  @include media("<tablet")
    animation-name: mainHide
    animation-delay: 0ms
    animation-duration: 300ms
    animation-iteration-count: 1
    animation-timing-function: linear
    animation-fill-mode: forwards


.main__section
  display: flex
  width: 100%
  flex-flow: column nowrap



.main__section-title
  display: none
  @include media("<large")
    display: none
  @include media("<desktop")
    display: none
  @include media("<tablet")
    display: inline


.main__text-wrap
  @include media("<tablet")
    padding: 0.5rem 2rem 0


.main__text
  p
    margin: 10px 0
  p + p
    margin-top: 10px
  @include media("<desktop")
  @include media("<tablet")
    display: none

.main__mobile-goback
  display: none
  @include media("<tablet")
    display: block
    width: 90px
    height: 20px
    margin: 30px 15px 2px
    appearance: none
    background: none
    border: none
    cursor: pointer
    svg


