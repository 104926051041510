@keyframes animateBtnBG
  from
    background-position: 100% 0
  to
    background-position: 0 0

.sliders-wrap
  position: relative
  margin-top: auto
  width: 100%
  background-color: #E1E1E1

.main-slider
  z-index: 2
  width: 780px
  height: 588px
  background-color: #E1E1E1
  @include media("<large")
    width: 648px
    height: 488px
  @include media("<desktop")
    width: 500px
    height: 378px
  @include media("<tablet")
    width: 100%
    height: 210px


  .swiper-wrapper
    display: flex
    align-items: stretch

  .swiper-slide
    display: flex
    justify-content: center
    align-items: center
    height: unset
    overflow: hidden
    picture
      width: 100%
      display: flex
      justify-content: center
      object-fit: contain
      img
        display: block
        object-fit: contain
        width: 100%
        @include media("<large")
          //width: 100%
        @include media("<desktop")
          //width: 100%
        @include media("<tablet")
          height: 210px
          width: 100%

//.text-slider
  width: calc(100% - 80px)
  height: 50px
  position: absolute !important
  left: 50%
  top: 4rem
  transform: translateX(-50%)
  z-index: 3 !important
  pointer-events: none !important
  opacity: 0.9
  @include media("<large")
    top: 2.1rem
  @include media("<desktop")
    width: calc(100% - 20px)
    top: 1.1rem
  @include media("<tablet")
    width: 100%
    bottom: 100%
    top: unset

  .swiper-slide
    padding: 9px 0 7px 16px
    color: $white
    font-family: "PT Mono", serif
    font-weight: 600
    user-select: none
    pointer-events: none !important

    p
      background-color: transparent
      margin: 0
      font-size: 1.4rem
      user-select: none
    p + p
      margin-top: 3px
    @include media("<tablet")
      p
        font-size: 1.1rem



.slider__btn-wrap
  position: absolute
  top: 3rem
  left: 3rem
  z-index: 4
  -webkit-transform: translate3d(0, 0, 0)
  @include media("<large")
    top: 2.5rem
    left: 2.5rem
  @include media("<desktop")
    top: 1.5rem
    left: 1.5rem
    z-index: 4


  button
    width: 4rem
    height: 4rem
    padding: 0
    outline: none
    border: none
    cursor: pointer
    opacity: 0.9
    transition: opacity $transition
    svg
      width: 29px
      margin-top: 2px
      fill: $white
    &:hover
      opacity: 0.8
    @include media("<large")
      width: 3.2rem
      height: 3.2rem
    @include media("<desktop")

    @include media("<tablet")
      width: 35px
      height: 35px
      svg
        width: 25px

  .slider-next
    background-size: 200% 100%
    background-position: 100%
    background-repeat: no-repeat

  .slider-next--animate
    animation: animateBtnBG
    animation-iteration-count: 1
    background-position: 0

  button + button
    margin-left: 1rem

  .swiper-button-disabled
    opacity: 0.3
